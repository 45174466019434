import * as yup from 'yup';

const schema = yup.object({
    //***** 1. Shipper Vessel Selection *****
    jdeOrderNumber: yup.string().nullable().required().label('JDE order #').meta({ section: 1, number: 1 }),
    facilityId: yup.number().nullable().required().label('Facility').meta({ section: 1, number: 2 }),
    customerAccountNumber: yup.string().nullable().required().label('Customer account #').meta({ section: 1, number: 3 }),
    shipperSentryNumber: yup.string().nullable().required().label('Shipper SENTRY #').meta({ section: 1, number: 4 }),
    shipperModelId: yup.number().nullable().required().label('Shipper model').meta({ section: 1, number: 5 }),
    shipperSerialNumber: yup.string().nullable().required().label('Shipper serial #').meta({ section: 1, number: 6 }),
    hasDryIce: yup.boolean().required().nullable().oneOf([true, false], 'Must be answered yes or no.').label("Does shipper already have dry ice in it?").meta({ section: 1, number: 7 }),
    lastSublimationCheck: yup.date().nullable().required().label('Latest sublimation rate check').meta({ section: 1, number: 8 }),
    lastSublimationRate: yup.number().nullable().required().label('Latest sublimation rate').meta({ section: 1, number: 9 }),

    //***** 2. Shipper Vessel Inspection *****
    isShipperAdequate: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Shipper interior and exterior in good condition?").meta({ section: 2, number: 1 }),
    isSerialNumberAdequate: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Manufacturer serial number label on shoulder and in good condition?").meta({ section: 2, number: 2 }),
    isDryIceShoulderLabelAdequate: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Dry ice cold hazard warning label on shoulder and in good condition?").meta({ section: 2, number: 3 }),
    isDryIceSideWallLabelAdequate: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Dry ice label on side wall and in good condition?").meta({ section: 2, number: 4 }),
    isProductLabelAdequate: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("SECCURUS product label on side wall opposite dry ice label and in good condition?").meta({ section: 2, number: 5 }),
    isManufacturerLabelAdequate: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Manufacturer information label on side wall below handle hinge and in good condition?").meta({ section: 2, number: 6 }),
    isBarrierTubeAdequate: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Barrier tube inside container in good condition?").meta({ section: 2, number: 7 }),
    isCapAdequate: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Cap in good condition?").meta({ section: 2, number: 8 }),

    //***** 3. Accessories Preparation *****
    productHolder: yup.string().nullable().required().label("Product holder?").meta({ section: 3, number: 1 }),
    isProductHolderAdequate: yup.boolean().nullable().when('productHolder', { is: (x) => x != "N/A", then: () => yup.boolean().nullable().oneOf([true], 'Must be answered yes.') }).label("Product holder in good condition?").meta({ section: 3, number: 2 }),
    probeType: yup.string().nullable().required().label("Temperature data logger and probe attached to cover?").meta({ section: 3, number: 3 }),
    probeSentryNumber: yup.string().nullable().when('probeType', { is: (x) => x !== null, then: () => yup.string().nullable().required() }).label("Temperature data logger and probe SENTRY number").meta({ section: 3, number: 4 }),
    probeCalibrationDate: yup.date().nullable().when('probeType', { is: (x) => x !== null, then: () => yup.date().nullable().required() }).label("Temperature data logger and probe usable end date").meta({ section: 3, number: 5 }),
    isProbePositionAdequate: yup.boolean().nullable().when('probeType', { is: (x) => x !== null, then: () => yup.boolean().required().label("Temperature probe positioned correctly").oneOf([true], 'Must be answered yes.') }).label("Is temperature probe positioned correctly?").meta({ section: 3, number: 6 }),
    probeUri: yup.string().nullable().when('probeType', { is: (x) => x === 'Elpro', then: () => yup.string().nullable().required() }).label("Start Elpro temperature monitor and enter Elpro LiveLink").meta({ section: 3, number: 7 }),

    //***** 4. Dry Ice Filling *****
    shipperTareWeight: yup.number().nullable().required().label("Shipper tare weight without cap?").meta({ section: 4, number: 1 }),
    shipperStartingWeight: yup.number().nullable().required().label("Shipper total starting weight without cap?").meta({ section: 4, number: 2 }),
    dryIceAddedWeight: yup.number().nullable().required().label("Dry Ice actually added to shipper?").meta({ section: 4, number: 3 }),
    shipperFillDate: yup.date().nullable().required().label('Dry Ice fill date').meta({ section: 4, number: 4 }),
    dryIceWeightInOtherApp: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Dry ice weight entered into the SECCURUS Web App?").meta({ section: 4, number: 5 }),
    isCapInstalled: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Cap installed?").meta({ section: 4, number: 6 }),

    //***** 5. Package Preparation *****
    isBoxAssemblyAdequate: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Corrugated box assembled appropriately?").meta({ section: 5, number: 1 }),
    isBoxFoamInstalled: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Foam inserts positioned inside box?").meta({ section: 5, number: 2 }),
    isCarrierPhoneNumberVisible: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Carrier phone number visible on exterior of shipping label pouch?").meta({ section: 5, number: 3 }),
    isQuickStartGuideAttached: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Quick start guide attached to interior box flap?").meta({ section: 5, number: 4 }),
    isDryIceLabelOnInteriorFlap: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Dry ice label attached to interior box flap?").meta({ section: 5, number: 5 }),
    isUserGuideOnTopOfFoam: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("User instructions on top of foam?").meta({ section: 5, number: 6 }),
    isShippingPouchAdequate: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Shipping label pouch with labels inside attached to interior box flap?").meta({ section: 5, number: 7 }),

    firstLegCarrier: yup.string().required().nullable().required("Please pick the first shipping company.").label("First destination shipping company?").meta({ section: 5, number: 8 }),
    firstLegTrackingNumber: yup.string().nullable().required().label("First destination tracking #").meta({ section: 5, number: 8.1 }),
    firstLegCity: yup.string().nullable().required().label("First destination city").meta({ section: 5, number: 8.2 }),
    firstLegStateProvince: yup.string().nullable().required().label("First destination state/province").meta({ section: 5, number: 8.3 }),

    secondLegCarrier: yup.string().nullable().label("Second destination shipping company?").meta({ section: 5, number: 9 }),
    secondLegTrackingNumber: yup.string().nullable().label("Second destination tracking #").meta({ section: 5, number: 9.1 }).when('secondLegCarrier', { is: (x) => x != null, then: () => yup.string().required() }),
    secondLegCity: yup.string().nullable()
        .when('secondLegTrackingNumber', { is: (x) => x != null && x.length > 0, then: () => yup.string().nullable().required() })
        .label("Second destination city").meta({ section: 5, number: 9.2 }),
    secondLegStateProvince: yup.string().nullable()
        .when('secondLegTrackingNumber', { is: (x) => x != null && x.length > 0, then: () => yup.string().nullable().required() })
        .label("Second destination state/province").meta({ section: 5, number: 9.3 }),

    isReturnLabelRequired: yup.boolean().nullable().required().label("Return shipping label required?").meta({ section: 5, number: 10 }),
    returnLegCarrier: yup.string().nullable()
        .when('isReturnLabelRequired', {
                is: (x) => x == true,
            then: () => yup.string().nullable().required("Please pick the return shipping company.")
        })
        .label("Return destination shipping company?").meta({ section: 5, number: 10.1 }),

    returnLegTrackingNumber: yup.string().nullable()
        .when('isReturnLabelRequired', {
            is: (x) => x == true,
            then: () => yup.string().nullable().required()
        })
        .label("Return destination tracking #").meta({ section: 5, number: 10.2 }),

    isClass9LabelAdequate: yup.boolean().nullable().oneOf([true], 'Must be answered yes.').meta({ section: 5, number: 11 }),

    //***** 6. Package Completion *****               
    isShipperOutboundInSentry: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Shipper registered as shipped out with SENTRY Scanner?").meta({ section: 6, number: 1 }),
    isFoamAndInstructionsPresent: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Top foam and user instructions inside the package?").meta({ section: 6, number: 2 }),
    isDataLoggerRunning: yup.boolean().nullable().when('probeType', { is: (x) => x !== null, then: () => yup.boolean().nullable().oneOf([true], 'Must be answered yes.') }).label("Temperature data logger started?").meta({ section: 6, number: 3 }),
    isDataLoggerOutboundInSentry: yup.boolean().nullable().when('probeType', { is: (x) => x !== null, then: () => yup.boolean().nullable().oneOf([true], 'Must be answered yes.') }).label("Temperature data logger registered as shipped out with SENTRY Scanner?").meta({ section: 6, number: 4 }),
    isBoxClosedAndTaped: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Box closed with tape?").meta({ section: 6, number: 5 }),
    isOutboundShippingLabelAttached: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Outbound shipping label attached to top of box?").meta({ section: 6, number: 6 }),
    isFillDateAndInitialsOnExterior: yup.boolean().required().nullable().oneOf([true], 'Must be answered yes.').label("Fill date and operator initials recorded on box exterior?").meta({ section: 6, number: 7 })
});

export default schema;