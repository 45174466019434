<script setup>
    import { ref, computed, watch, onMounted } from 'vue';
    import axios from 'axios';
    import schema from './schema.js';
    import moment from 'moment';
    import Section from "@/components/ChecklistSection.vue";
    import helpers from '@/checklist-helpers';
    import utils from '@/utils.js';
    import constants from '@/constants.js';
    import { useToast } from 'vue-toastification';
    import { useRoute, useRouter } from 'vue-router'

    let watchers = [];
    let debounce = utils.createDebounce();

    const props = defineProps(["user"]);    
    const sections = ref([]);
    const shipperModels = ref([]);
    const facilities = ref([]);
    const stateProvinces = ref([]);
    const carriers = ref([]);
    const modified = ref(false);
    const submitted = ref(false);
    const isReadonly = ref(true);
    const customerAccount = ref(null);
    const router = useRouter();
    const route = useRoute();

    const isShipmentComplete = ref(false);
    const showAdminOnlyWarning = ref(false);
    const model = ref({ created: new Date() });
        
    const formatErrors = (errors) => helpers.formatErrors(schema, errors);    
    const getFieldMeta = (fieldName) => helpers.getFieldMeta(schema, fieldName);
    const getFieldLabel = (fieldName) => helpers.getFieldLabel(schema, fieldName);
    const toggleSections = (value) => sections.value.forEach(el => el.expanded = value);
    const setSectionRef = (el) => { if (el) sections.value.push(el); };
    const scrollToErrors = () => window.scrollTo(0, 25);

    async function saveAsPublished() {        
        model.value.submitterId = props.user.id;
        model.value.submitted = utils.getIsoDate();
        await save();
        await router.push({ name: 'PreparationChecklists' });
        useToast().success("The checklist was published successfully.")
    }

    async function saveAsDraft() {
        model.value.submitterId = null;
        model.value.submitted = null;
        await save();
        await router.push({ name: 'PreparationChecklists' });
        useToast().success("The checklist was saved as draft successfully.");
    }

    async function save() {    
        return new Promise( async (resolve, reject) => {            
            const response = await axios.post('/api/PreparationChecklist/Save', model.value);
            
            if (response.status !== 200) {
                useToast().error(utils.formatError(response));
                return;
            }

            modified.value = false;
            model.value = response.data;
            setupWatches();
            resolve();
        });
    }

    function onSubmit(values, actions) {
        submitted.value = true;

        let sublimationErrors = getSublimationCheckState().filter(x => x.state && x.state == 'error');
        let dataLoggerErrors = getDataLoggerState().filter(x => x.state && x.state == 'error');
        let dryIceErrors = getDryIceState().filter(x => x.state && x.state == 'error');

        sublimationErrors.forEach(x => actions.setFieldError("lastSublimationCheck", x.message));
        dataLoggerErrors.forEach(x => actions.setFieldError("probeCalibrationDate", x.message));
        dryIceErrors.forEach(x => actions.setFieldError("dryIceAddedWeight", x.message));

        if (sublimationErrors.concat(dataLoggerErrors).concat(dryIceErrors).length > 0) {
            scrollToErrors();
            return;
        }

        saveAsPublished();
    }
    
    function onInvalidSubmit() {
        scrollToErrors();
        submitted.value = true;
    }

    function setupWatches() {
        //kill the watch if its already running
        watchers.forEach(x => x());
        watchers = [];

        modified.value = false;

        watchers.push(
            watch(
                () => model.value,
                () => modified.value = true,
                { deep: true }
            )
        );

        watchers.push(
            watch(
                () => model.value.shippingCarrier,
                (oldVal, newVal) => {                    
                    if (oldVal > 0) {
                        //clear out tracking numbers when changing shipper information
                        model.value.isClass9LabelAdequate = null;
                        model.value.firstLegTrackingNumber = null;
                        model.value.secondLegTrackingNumber = null;
                        model.value.returnLegTrackingNumber = null;
                    }
                }
            )
        );

        watchers.push(
            watch(
                () => model.value.shipperModelId,
                (oldVal, newVal) => {
                    if (oldVal > 0) {
                        //clear out shipper fields when model changes
                        model.value.shipperSerialNumber = null;
                        model.value.sentryNumber = null;
                        model.value.hasDryIce = null;
                        model.value.lastSublimationCheck = null;
                        model.value.lastSublimationRate = null;
                        model.value.shipperFillDate = null;
                    }
                }
            )
        );
    }

    function getSublimationCheckState() {
        let messages = [];

        if (model.value.submitted) return messages;

        let daysAgo = null;
        if (model.value && model.value.lastSublimationCheck) {
            let checklistDate = moment();
            let b = moment(model.value.lastSublimationCheck);
            daysAgo = Math.abs(b.diff(checklistDate, 'days'));
        }

        if (daysAgo != null) {
            if ((model.value.hasDryIce == true && daysAgo > 15) || (model.value.hasDryIce == false && daysAgo > 7))
                messages.push({ message: "Latest sublimation rate check is too old. Sublimation rate should be measured. <a href='#S1_Q5'>Select another shipper.</a>", state: 'error' });
            else
                messages.push({ message: `It has been ${daysAgo} days since the last sublimation rate check `, state: 'success' });
        }

        if (selectedShipperModel.value && model.value.lastSublimationRate && (model.value.lastSublimationRate > selectedShipperModel.value.cutOffSublimationRate))
            messages.push({ message: `Sublimation rate exceeds cut-off. Shipper should be sent for maintenance. <a href='#S1_Q5'>Select another shipper.</a>`, state: 'error' });

        return messages;
    }

    function getDataLoggerState() {
        let messages = [];
        if (model.value.submitted || model.value.probeType != null) return messages;

        let daysAgo = null;

        if (model.value && model.value.probeCalibrationDate) {
            let checklistDate = model.value.submitted ? moment(model.value.submitted) : moment();
            let calibrationDate = moment(model.value.probeCalibrationDate);
            daysAgo = checklistDate.diff(calibrationDate, 'days');
        }

        if (daysAgo != null && selectedShipperModel.value != null) {
            let gracePeriod = selectedShipperModel.value.temperatureProbeCalibrationRequirement;

            if (gracePeriod && daysAgo > gracePeriod) {
                messages.push({ message: "Calibration for temperature data logger and probe has expired or will not last through shipment. Discard data logger and probe and select another set.", state: 'error' });
                model.value.probeSentryNumber = null;
                model.value.probeCalibrationDate = null;
                model.value.isProbePositionAdequate = null;
                model.value.isDataLoggerRunning = null;
                model.value.isDataLoggerOutboundInSentry = null;
            }
        }

        return messages;
    }

    function getDryIceState() {
        if (dryIceToBeAddedToShipper.value == null) return [];

        let messages = [];
        if (model.value.dryIceAddedWeight < dryIceToBeAddedToShipper.value) {
            messages.push({ message: "Add more dry ice. Dry ice actually added should equal or exceed &quot;Dry Ice to be Added&quot;", state: 'error' });
        }

        return messages;
    }

    function getReturnAddress() {        
        let matches = facilities.value.filter(x => x.id == (model.value?.facilityId || -1));
        if (matches.length == 1) {
            let facility = matches[0];
            return `The shipper will return to the "${facility.name}" facility (${facility.city}, ${facility.stateProvince}, ${facility.postalCode})`;
        }
        return "The shipper will return to the facility.";
    }

    function formatMessages(messages) {
        if (messages.length == 0) return "";
        let html = '<div class="notification">';

        messages.forEach(data => {
            let textClass = data.state == 'error' ? 'danger has-text-weight-bold' : data.state == 'warning' ? 'warn' : 'normal';
            let icon = data.state == 'error' ? 'error' : data.state == 'warning' ? 'warning' : data.state == 'success' ? 'check' : 'info';
            html += `<div class='has-text-${textClass}'><span class='material-icons md-18 mr-2'>${icon}</span>${data.message}</div>`;
        })

        html += "</div>";
        return html;
    }

    async function findCustomerAccount() {        
        if (model.value.customerAccountNumber == null || model.value.customerAccountNumber.trim().length == 0) return;
        const response = await axios.get(`/api/Customer/AccountByNumber/${model.value.customerAccountNumber}`);
        if (response.status !== 200) return;
        customerAccount.value = response.data;
        if (response.data.id) model.value.customerAccountId = response.data.id;
    }
    
    async function loadLookups() {
        await Promise.all( [
            axios.get('/api/ShipperModel/List').then(x => shipperModels.value = x.data),
            axios.get(`/api/Facility/List/${constants.FacilityFlags.FillStation}`).then(x => facilities.value = x.data),
            axios.get('/api/Tracking/StateProvinces/usa').then(x => stateProvinces.value = x.data),
            axios.get('/api/Tracking/ListCarriers').then(x => carriers.value = x.data)
        ]);                
    }

    async function load() {
        loadLookups();        
        const response = await axios.get(`/api/PreparationChecklist/Get/${(route.params.id || '')}`);
        if (response.status !== 200) return;
        model.value = response.data.model;
        findCustomerAccount();
        isShipmentComplete.value = response.data.isShipmentComplete;
        isReadonly.value = model.value.permissions.indexOf("w") < 0;

        if (model.value.submitted != null) {
            showAdminOnlyWarning.value = true;
            if (!isAdmin.value) setTimeout( helpers.disableFormControls(document.forms[0]) , 1000);
        }

        setupWatches();        
    }

    const selectedShipperModel = computed(() => {
        if (!model.value && !model.value.shipperModelId) return null;
        let matches = shipperModels.value.filter(x => x.id == model.value.shipperModelId);
        return matches.length == 1 ? matches[0] : null;
    });

    const dryIceToBeAddedToShipper = computed(() => {
        if (model.value && model.value.shipperTareWeight != null && model.value.shipperStartingWeight != null) {
            let shipper = selectedShipperModel.value;
            if (shipper != null) return shipper.maxDryIceFillWeight - (model.value.shipperStartingWeight - model.value.shipperTareWeight);
        }
        return null;
    });

    const dryIceMessages = computed(() => formatMessages(getDryIceState()));
    const sublimationMessages = computed(() => formatMessages(getSublimationCheckState()));
    const dataLoggerMessages = computed(() => formatMessages(getDataLoggerState()));
    const isAdmin = computed(() => props.user && props.user.securityRole == constants.SecurityRole.Admin);
    const isMetric = computed(() => model.value.unitSystem === constants.UnitSystem.Metric);          
        
    onMounted( () => load() );
</script>

<template>
    <div class="container">
        <ConfirmModal ref="confirmModal" />
        
        <div class="notification is-info" v-show="showAdminOnlyWarning == true">
            <i class="material-icons is-pulled-left mr-2">info</i>
            <h2>This checklist has been submitted, only an admin can update it.</h2>
        </div>

        <div class="columns">
            <div class="column">
                <p class="title"><span class="material-icons mr-1">local_shipping</span>Dry Ice Shipper Package Preparation Checklist</p>
            </div>
            <div class="column is-narrow">
                <div class="buttons">
                    <router-link class="button" :to="{ name : 'PreparationChecklists' }" title="Return to List">
                        <i class="material-icons md-18">list</i>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="columns is-gapless">
            <div class="column">
                <div v-if="model && model.author">
                    Created By: {{model.author.fullName}} on {{utils.formatDate(model.created)}}
                </div>
                <div v-if="model && model.submitter">
                    Submitted By: {{model.submitter.fullName}} on {{utils.formatDate(model.submitted)}}
                </div>
            </div>
            <div class="column is-narrow">
                <span class="has-text-link cursor-pointer" title="Expand sections" @click="toggleSections(true)">Expand All</span> |
                <span class="has-text-link cursor-pointer" title="Collapse sections" @click="toggleSections(false)">Collapse All</span>
            </div>
        </div>

        <Form v-slot="{errors}" @invalid-submit="onInvalidSubmit" @submit="onSubmit" :validation-schema="schema" @keydown.enter="$event.preventDefault()">
            <template v-if="Object.keys(errors).length">
                <div class="notification is-warning" v-if="submitted == true">
                    <p>Please correct the following errors</p>
                    <ul>
                        <li v-for="item in formatErrors(errors)" :key="item">
                            <a :href="`#S${item.meta.section}_Q${item.meta.number}`">
                                {{item.meta.section}}.{{item.meta.number}} - <span v-html="item.message"></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </template>

            <Section title="1. Shipper Vessel Selection" :ref="setSectionRef">
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div v-html='getFieldLabel("jdeOrderNumber")'></div>
                            <div class="control has-icons-right" title="scan ticket">
                                <Field class="input" type="text" placeholder="JDE Order #" name="jdeOrderNumber" v-model="model.jdeOrderNumber" />
                                <span class="icon is-small is-right">
                                    <span class="material-icons md-18">barcode_reader</span>
                                </span>
                            </div>
                        </div>

                        <div class="field">
                            <div v-html='getFieldLabel("facilityId")'></div>
                            <div class="select control is-fullwidth">
                                <Field as="select" name="facilityId" v-model="model.facilityId">
                                    <option :value="null"></option>
                                    <option v-for="option in facilities" v-bind:key="option.id" v-bind:value="option.id">
                                        {{option.name}}
                                    </option>
                                </Field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div v-html='getFieldLabel("customerAccountNumber")'></div>
                            <div class="control has-icons-right" title="scan ticket">
                                <Field class="input" type="text" placeholder="Customer Account #" name="customerAccountNumber" v-model="model.customerAccountNumber"
                                       @input="debounce(findCustomerAccount)" />
                                <span class="icon is-small is-right">
                                    <span class="material-icons md-18">barcode_reader</span>
                                </span>
                            </div>

                            <div v-if="customerAccount" class="has-text-info is-italic" title="Customer account details">
                                {{customerAccount.customerName}}
                            </div>
                        </div>

                        <div class="field">
                            <div v-html='getFieldLabel("shipperSentryNumber")'></div>
                            <div class="control has-icons-right" title="scan barcode">
                                <Field class="input" type="text" placeholder="Shipper Sentry #" name="shipperSentryNumber" v-model="model.shipperSentryNumber" />
                                <span class="icon is-small is-right">
                                    <span class="material-icons md-18">barcode_reader</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div v-html='getFieldLabel("shipperModelId")'></div>
                            <div class="select control is-fullwidth">
                                <Field as="select" name="shipperModelId" v-model="model.shipperModelId">
                                    <option :value="null"></option>
                                    <option v-for="option in shipperModels" v-bind:key="option.id" v-bind:value="option.id">
                                        {{option.name}}
                                    </option>
                                </Field>
                            </div>
                        </div>

                        <div class="field">
                            <div v-html='getFieldLabel("shipperSerialNumber")'></div>
                            <div class="control has-icons-right" title="scan QR code">
                                <Field class="input" type="text" placeholder="Shipper Serial #" name="shipperSerialNumber" v-model="model.shipperSerialNumber" />
                                <span class="icon is-small is-right">
                                    <span class="material-icons md-18">qr_code</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box has-background-info-light" v-if="selectedShipperModel">
                    <div class="columns">
                        <div class="column is-narrow" v-if="selectedShipperModel && selectedShipperModel.images.length > 0">
                            <figure class="image">
                                <img v-bind:src="`/api/ShipperModel/Image/${selectedShipperModel.images[0].id}`" style="max-width: 100%; height: auto; max-height: 200px" />
                            </figure>
                        </div>
                        <div class="column">
                            <div class="field">
                                <label class="label">Dry Ice Hold Time</label>
                                {{utils.formatNumber(selectedShipperModel.dryIceHoldTime,2)}} days
                            </div>

                            <div class="field">
                                <label class="label">Maximum cut-off sublimation rate</label>
                                {{utils.formatNumber(selectedShipperModel.cutOffSublimationRate,3)}} {{(isMetric ? "kg" : "lb")}}/day
                            </div>

                            <div class="field">
                                <label class="label">Dimensions</label>
                                {{selectedShipperModel.packageLength}} {{(isMetric ? "cm" : "in")}} x
                                {{selectedShipperModel.packageWidth}} {{(isMetric ? "cm" : "in")}} x
                                {{selectedShipperModel.packageHeight}} {{(isMetric ? "cm" : "in")}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("hasDryIce")'></div>
                    <div class="control">
                        <label class="radio">
                            <Field type="radio" name="hasDryIce" v-model="model.hasDryIce" :value="true" />
                            Yes
                        </label>
                        <label class="radio">
                            <Field type="radio" name="hasDryIce" v-model="model.hasDryIce" :value="false" />
                            No
                        </label>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div v-html='getFieldLabel("lastSublimationCheck")'></div>
                            <DatePicker input-class="input" v-model="model.lastSublimationCheck" :maxDate="new Date()" name="lastSublimationCheckEx"></DatePicker>
                            <Field type="hidden" v-model="model.lastSublimationCheck" name="lastSublimationCheck" />
                        </div>

                        <div class="field">
                            <div>
                                <span v-html='getFieldLabel("lastSublimationRate")' class="is-pulled-left"></span>
                                <span class="material-icons md-18 ml-2 cursor-help" title="Please enter a number with 2 decimal points">help</span>
                            </div>
                            <UnitEditor name="lastSublimationRate" v-model="model.lastSublimationRate">{{(isMetric ? "kg" : "lb")}}/day</UnitEditor>
                        </div>
                    </div>
                </div>

                <div v-html="sublimationMessages"></div>
            </Section>

            <Section title="2. Shipper Vessel Inspection" :ref="setSectionRef">
                <div class="field">
                    <div v-html='getFieldLabel("isShipperAdequate")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isShipperAdequate" :value="true" v-model="model.isShipperAdequate" /> Yes
                    </label>
                </div>
                <div class="field">
                    <div v-html='getFieldLabel("isSerialNumberAdequate")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isSerialNumberAdequate" :value="true" v-model="model.isSerialNumberAdequate" /> Yes
                    </label>
                </div>
                <div class="field">
                    <div v-html='getFieldLabel("isDryIceShoulderLabelAdequate")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isDryIceShoulderLabelAdequate" :value="true" v-model="model.isDryIceShoulderLabelAdequate" /> Yes
                    </label>
                </div>
                <div class="field">
                    <div v-html='getFieldLabel("isDryIceSideWallLabelAdequate")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isDryIceSideWallLabelAdequate" :value="true" v-model="model.isDryIceSideWallLabelAdequate" /> Yes
                    </label>
                </div>
                <div class="field">
                    <div v-html='getFieldLabel("isProductLabelAdequate")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isProductLabelAdequate" :value="true" v-model="model.isProductLabelAdequate" /> Yes
                    </label>
                </div>
                <div class="field">
                    <div v-html='getFieldLabel("isManufacturerLabelAdequate")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isManufacturerLabelAdequate" :value="true" v-model="model.isManufacturerLabelAdequate" /> Yes
                    </label>
                </div>
                <div class="field">
                    <div v-html='getFieldLabel("isBarrierTubeAdequate")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isBarrierTubeAdequate" :value="true" v-model="model.isBarrierTubeAdequate" /> Yes
                    </label>
                </div>
                <div class="field">
                    <div v-html='getFieldLabel("isCapAdequate")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isCapAdequate" :value="true" v-model="model.isCapAdequate" /> Yes
                    </label>
                </div>
            </Section>

            <Section title="3. Accessories Preparation" :ref="setSectionRef">
                <div class="field">
                    <div v-html='getFieldLabel("productHolder")'></div>
                    <div class="control">
                        <label class="radio">
                            <Field type="radio" name="productHolder" v-model="model.productHolder" value="Canister" /> Canister
                        </label>
                        <label class="radio">
                            <Field type="radio" name="productHolder" v-model="model.productHolder" value="Rack" /> Rack
                        </label>
                        <label class="radio">
                            <Field type="radio" name="productHolder" v-model="model.productHolder" value="N/A" /> N/A
                        </label>
                    </div>
                </div>

                <div class="field" v-show="model.productHolder == 'Rack' || model.productHolder == 'Canister'">
                    <div><span v-html='getFieldLabel("isProductHolderAdequate")' class="is-pulled-left"></span> <span class="has-text-danger md-16">*</span></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isProductHolderAdequate" :value="true" v-model="model.isProductHolderAdequate" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("probeType")'></div>
                    <div class="control">
                        <label class="radio">
                            <Field type="radio" name="probeType" value="TempTale" v-model="model.probeType" /> TempTale
                        </label>
                        <label class="radio">
                            <Field type="radio" name="probeType" value="Elpro" v-model="model.probeType" /> Elpro
                        </label>
                        <label class="radio">
                            <Field type="radio" name="probeType" :value="null" v-model="model.probeType" /> N/A
                        </label>
                    </div>
                </div>

                <div v-show="model.probeType !== null">
                    <div class="field">
                        <div v-html='getFieldLabel("probeSentryNumber")'></div>
                        <div class="control has-icons-right" title="scan barcode">
                            <Field class="input" type="text" name="probeSentryNumber" v-model="model.probeSentryNumber" />
                            <span class="icon is-small is-right">
                                <span class="material-icons md-18">barcode_reader</span>
                            </span>
                        </div>
                    </div>

                    <div class="field">
                        <div>
                            <span v-html='getFieldLabel("probeCalibrationDate")' class="is-pulled-left"></span>
                            <span class="material-icons md-18 ml-2 cursor-help" title="Select the month listed on sticker on back of data logger.">help</span>
                        </div>

                        <div class="control">
                            <MonthPicker v-model="model.probeCalibrationDate" name="probeCalibrationMonth" />
                            <Field type="hidden" v-model="model.probeCalibrationDate" name="probeCalibrationDate" />
                        </div>
                    </div>

                    <div class="field">
                        <div v-html='getFieldLabel("isProbePositionAdequate")'></div>                        
                        <div class="control">
                            <label class="checkbox">
                                <Field type="checkbox" name="isProbePositionAdequate" :value="true" v-model="model.isProbePositionAdequate" /> Yes
                            </label>
                        </div>
                    </div>

                    <div v-html="dataLoggerMessages"></div>

                    <div class="field" v-show="model.probeType == 'Elpro'">
                        <div v-html='getFieldLabel("probeUri")'></div>
                        <div class="control has-icons-right" title="scan barcode">
                            <Field class="input" type="text" placeholder="Livelink" name="probeUri" v-model="model.probeUri" />
                            <span class="icon is-small is-right">
                                <span class="material-icons md-18">link</span>
                            </span>
                        </div>
                    </div>
                </div>
            </Section>

            <Section title="4. Dry Ice Filling" :ref="setSectionRef">
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div>
                                <span v-html='getFieldLabel("shipperTareWeight")' class="is-pulled-left"></span>
                                <span class="material-icons md-18 ml-2 cursor-help" title="Please enter a number with 2 decimal points">help</span>
                            </div>
                            <UnitEditor name="shipperTareWeight" v-model="model.shipperTareWeight">{{(isMetric ? "kg" : "lb")}}</UnitEditor>
                        </div>
                        <div class="field">
                            <div>
                                <span v-html='getFieldLabel("shipperStartingWeight")' class="is-pulled-left"></span>
                                <span class="material-icons md-18 ml-2 cursor-help" title="Please enter a number with 2 decimal points">help</span>
                            </div>
                            <UnitEditor name="shipperStartingWeight" v-model="model.shipperStartingWeight">{{(isMetric ? "kg" : "lb")}}</UnitEditor>
                        </div>
                    </div>
                </div>
                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field" v-if="selectedShipperModel">
                            <label class="label">Maximum Dry Ice Fill Weight:</label>
                            <div class="box has-background-info-light">
                                {{utils.formatNumber(selectedShipperModel.maxDryIceFillWeight,2)}} {{(isMetric ? "kg" : "lb")}}
                            </div>
                        </div>

                        <div class="field">
                            <label class="label">Dry Ice to be Added to Shipper:</label>
                            <div class="box has-background-info-light">
                                {{utils.formatNumber(dryIceToBeAddedToShipper,2)}} {{(isMetric ? "kg" : "lb")}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field is-horizontal">
                    <div class="field-body">
                        <div class="field">
                            <div>
                                <span v-html='getFieldLabel("dryIceAddedWeight")' class="is-pulled-left"></span>
                                <span class="material-icons md-18 ml-2 cursor-help" title="Please enter a number with 2 decimal points">help</span>
                            </div>
                            <UnitEditor name="dryIceAddedWeight" v-model="model.dryIceAddedWeight">{{(isMetric ? "kg" : "lb")}}</UnitEditor>
                        </div>

                        <div class="field">
                            <div>
                                <span v-html='getFieldLabel("shipperFillDate")'></span>
                            </div>
                            <DatePicker input-class="input" v-model="model.shipperFillDate" name="shipperFillDate"></DatePicker>
                            <Field type="hidden" v-model="model.shipperFillDate" name="shipperFillDate" />
                        </div>
                    </div>
                </div>

                <div v-html="dryIceMessages"></div>

                <div class="field">
                    <div v-html='getFieldLabel("dryIceWeightInOtherApp")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="dryIceWeightInOtherApp" :value="true" v-model="model.dryIceWeightInOtherApp" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isCapInstalled")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isCapInstalled" :value="true" v-model="model.isCapInstalled" /> Yes
                    </label>
                </div>
            </Section>

            <Section title="5. Package Preparation" :ref="setSectionRef">
                <div class="field">
                    <div v-html='getFieldLabel("isBoxAssemblyAdequate")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isBoxAssemblyAdequate" :value="true" v-model="model.isBoxAssemblyAdequate" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isBoxFoamInstalled")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isBoxFoamInstalled" :value="true" v-model="model.isBoxFoamInstalled" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isCarrierPhoneNumberVisible")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isCarrierPhoneNumberVisible" :value="true" v-model="model.isCarrierPhoneNumberVisible" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isQuickStartGuideAttached")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isQuickStartGuideAttached" :value="true" v-model="model.isQuickStartGuideAttached" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isDryIceLabelOnInteriorFlap")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isDryIceLabelOnInteriorFlap" :value="true" v-model="model.isDryIceLabelOnInteriorFlap" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isUserGuideOnTopOfFoam")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isUserGuideOnTopOfFoam" :value="true" v-model="model.isUserGuideOnTopOfFoam" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isShippingPouchAdequate")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isShippingPouchAdequate" :value="true" v-model="model.isShippingPouchAdequate" /> Yes
                    </label>
                </div>

                <div class="box p-0">
                    <div class="columns is-marginless">
                        <div class="column is-half">
                            <div v-html='getFieldLabel("firstLegCarrier")'></div>
                            <div class="select control is-fullwidth">
                                <Field as="select" name="firstLegCarrier" v-model="model.firstLegCarrier">
                                    <option :value="null"></option>
                                    <option v-for="option in carriers" v-bind:key="option.slug" v-bind:value="option.slug">
                                        {{option.name}}
                                    </option>
                                </Field>
                            </div>
                        </div>
                        <div class="column">
                            <div v-html='getFieldLabel("firstLegTrackingNumber")'></div>
                            <div class="control has-icons-right" title="scan barcode">
                                <Field class="input" type="text" name="firstLegTrackingNumber" v-model="model.firstLegTrackingNumber" />
                                <span class="icon is-small is-right">
                                    <span class="material-icons md-18">barcode_reader</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-marginless">
                        <div class="column is-half">
                            <div v-html='getFieldLabel("firstLegCity")'></div>
                            <Field class="input" type="text" name="firstLegCity" v-model="model.firstLegCity" />
                        </div>
                        <div class="column">
                            <div v-html='getFieldLabel("firstLegStateProvince")'></div>
                            <div class="select control is-fullwidth">
                                <Field as="select" name="firstLegStateProvince" v-model="model.firstLegStateProvince">
                                    <option :value="null"></option>
                                    <option v-for="option in stateProvinces" v-bind:key="option.abbreviation" v-bind:value="option.abbreviation">
                                        {{option.abbreviation}} - {{option.name}}
                                    </option>
                                </Field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box p-0">
                    <div class="columns is-marginless">
                        <div class="column is-half">
                            <div v-html='getFieldLabel("secondLegCarrier")'></div>
                            <div class="select control is-fullwidth">
                                <Field as="select" name="secondLegCarrier" v-model="model.secondLegCarrier">
                                    <option :value="null"></option>
                                    <option v-for="option in carriers" v-bind:key="option.slug" v-bind:value="option.slug">
                                        {{option.name}}
                                    </option>
                                </Field>
                            </div>
                        </div>
                        <div class="column">
                            <div v-html='getFieldLabel("secondLegTrackingNumber")'></div>
                            <div class="control has-icons-right" title="scan barcode">
                                <Field class="input" type="text" name="secondLegTrackingNumber" v-model="model.secondLegTrackingNumber" />
                                <span class="icon is-small is-right">
                                    <span class="material-icons md-18">barcode_reader</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="columns is-marginless">
                        <div class="column is-half">
                            <div v-html='getFieldLabel("secondLegCity")'></div>
                            <Field class="input" type="text" name="secondLegCity" v-model="model.secondLegCity" />
                        </div>
                        <div class="column">
                            <div v-html='getFieldLabel("secondLegStateProvince")'></div>
                            <div class="select control is-fullwidth">
                                <Field as="select" name="secondLegStateProvince" v-model="model.secondLegStateProvince">
                                    <option :value="null"></option>
                                    <option v-for="option in stateProvinces" v-bind:key="option.abbreviation" v-bind:value="option.abbreviation">
                                        {{option.abbreviation}} - {{option.name}}
                                    </option>
                                </Field>
                            </div>
                        </div>
                    </div>
                </div>                

                <div class="box p-0">   
                    <div class="columns is-marginless">
                        <div class="column is-half">
                            <div class="field">
                                <div>
                                    <span v-html='getFieldLabel("isReturnLabelRequired")' class="is-pulled-left"></span>
                                    <span class="material-icons md-18 ml-2 cursor-help" title="Return shipping labels may be optional for Customers who keep shippers for an extended time period and refill shippers with dry ice on their own.">help</span>
                                </div>

                                <div class="control">
                                    <label class="radio">
                                        <Field type="radio" name="isReturnLabelRequired" v-model="model.isReturnLabelRequired" :value="true" />
                                        Yes
                                    </label>
                                    <label class="radio">
                                        <Field type="radio" name="isReturnLabelRequired" v-model="model.isReturnLabelRequired" :value="false" />
                                        No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="model.isReturnLabelRequired != false">
                        <div class="columns is-marginless">
                            <div class="column is-half">
                                <div v-html='getFieldLabel("returnLegCarrier")'></div>
                                <div class="select control is-fullwidth">
                                    <Field as="select" name="returnLegCarrier" v-model="model.returnLegCarrier">
                                        <option :value="null"></option>
                                        <option v-for="option in carriers" v-bind:key="option.slug" v-bind:value="option.slug">
                                            {{option.name}}
                                        </option>
                                    </Field>
                                </div>
                            </div>
                            <div class="column">
                                <div v-html='getFieldLabel("returnLegTrackingNumber")'></div>
                                <div class="control has-icons-right" title="scan barcode">
                                    <Field class="input" type="text" name="returnLegTrackingNumber" v-model="model.returnLegTrackingNumber" />
                                    <span class="icon is-small is-right">
                                        <span class="material-icons md-18">barcode_reader</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <p class="is-italic p-2 has-text-weight-bold has-text-info-dark">
                            {{getReturnAddress()}}
                        </p>
                    </div>
                </div>

                <div class="field" v-if="model.firstLegCarrier">
                    <span v-if="model.firstLegCarrier != 'ups'">
                        <label class="label">5.11 UN1845 Dry Ice Class 9 diamond label attached to box exterior with dry ice amount (kg), shipper's name and address, and recipient's name and address recorded?</label>
                    </span>
                    <label class="label" v-if="model.firstLegCarrier == 'ups'">5.11 UPS Sticker &quot;Dry Ice for Diagnostic or Medical Purposes Only&quot; label attached box exterior with dry ice weight recorded in kg?</label>
                    <label class="checkbox">
                        <Field type="checkbox" name="isClass9LabelAdequate" :value="true" v-model="model.isClass9LabelAdequate" /> Yes
                    </label>
                </div>
            </Section>

            <Section title="6. Package Completion" :ref="setSectionRef">
                <div class="field">
                    <div v-html='getFieldLabel("isShipperOutboundInSentry")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isShipperOutboundInSentry" :value="true" v-model="model.isShipperOutboundInSentry" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isFoamAndInstructionsPresent")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isFoamAndInstructionsPresent" :value="true" v-model="model.isFoamAndInstructionsPresent" /> Yes
                    </label>
                </div>

                <div class="field" v-show="model.probeType == 'TempTale'">
                    <div>
                        <span v-html='getFieldLabel("isDataLoggerRunning")' class="is-pulled-left"></span>
                        <span class="material-icons md-18 ml-2 cursor-help" title="For TempTale, the sun and hourglass symbols will appear at the top of the screen.">help</span>
                    </div>

                    <label class="checkbox">
                        <Field type="checkbox" name="isDataLoggerRunning" :value="true" v-model="model.isDataLoggerRunning" /> Yes
                    </label>
                </div>

                <div class="field" v-show="model.probeType !== null">
                    <div v-html='getFieldLabel("isDataLoggerOutboundInSentry")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isDataLoggerOutboundInSentry" :value="true" v-model="model.isDataLoggerOutboundInSentry" /> Yes
                    </label>
                </div>

                <div class="field mt-2">
                    <div v-html='getFieldLabel("isBoxClosedAndTaped")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isBoxClosedAndTaped" :value="true" v-model="model.isBoxClosedAndTaped" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isOutboundShippingLabelAttached")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isOutboundShippingLabelAttached" :value="true" v-model="model.isOutboundShippingLabelAttached" /> Yes
                    </label>
                </div>

                <div class="field">
                    <div v-html='getFieldLabel("isFillDateAndInitialsOnExterior")'></div>
                    <label class="checkbox">
                        <Field type="checkbox" name="isFillDateAndInitialsOnExterior" :value="true" v-model="model.isFillDateAndInitialsOnExterior" /> Yes
                    </label>
                </div>
            </Section>
            
            <div class="buttons" v-if="!isReadonly">
                <button class="button is-primary" type="button" @click="saveAsDraft">Save As Draft</button>
                <button class="button is-success" type="submit">Submit</button>
            </div>
        </Form>
    </div>
</template>

<style scoped>
    .errors {
        display: block;
        margin-bottom: 5px;
    }
</style>

