<script setup>
    import { onMounted, computed, ref } from 'vue';
    import axios from 'axios';

    const itemState = ref([]);
    const emit = defineEmits(['changed']); 

    async function load() {
        const response = await axios.get("/api/Customer/List");
        if (response.status !== 200) return;
        itemState.value = response.data.map(x => ({ model: x, expanded: false, selected: false, children: null }));
    }

    function getSummary() {
        var selection = itemState.value            
            .map(x => ({
                id: x.model.id,
                selected: x.selected,
                children: (x.children || []).filter(x => x.selected == true).map(c => c.model.id)
            }))
            .filter(x => x.selected == true || x.children.length > 0)
            .map(x => ({ id: x.id, selected: x.selected, children: x.children }))

        return selection;
    }

    async function loadAccounts(state) {
        const response = await axios.get(`/api/Customer/Accounts/${state.model.id}`);
        if (response.status !== 200) return;
        state.children = response.data.map(x => ({ model: x, selected: state.selected }));
    }

    async function toggleExpansion(state) {        
        if (state.expanded == false) {
            if (state.children == null) {
                await loadAccounts(state);
            }
        }
        state.expanded = !state.expanded;
    }

    async function parentSelectionChanged(event, state) {        
        var selected = event.target.checked;        

        if (state.children != null ) {
            state.children.forEach(x => x.selected = selected);
        }

        emit('changed', getSummary());
    }

    async function childSelectionChanged(event, state) {        
        var selected = event.target.checked;
        var parent = itemState.value.find(x => x.children != null && x.children.indexOf(state) >= 0);

        if (parent == null) return;
        var selectedCount = parent.children.filter(x => x.selected == true).length;
        if (selectedCount !== parent.children.length) parent.selected = false; //everything is checked
        if (selectedCount == parent.children.length) parent.selected = true;
        emit('changed', getSummary());
    }

    onMounted(() => {
        load();
    })
</script>

<template>
    <div>        
        <div v-for="item in itemState">
            <div class="columns is-gapless" style="line-height:1">
                <div class="column is-narrow">
                    <a @click="toggleExpansion(item)">
                        <i class="material-icons md-18">{{(item.expanded == true ? "remove" : "add")}}</i>
                    </a>
                </div>
                <div class="column">
                    <label class="checkbox">
                        <input type="checkbox" v-model="item.selected" class="mr-1" @change="parentSelectionChanged($event, item)">{{item.model.name}}
                    </label>
                    <div class="ml-3" v-for="child in item.children" v-if="item.expanded == true">
                        <label class="checkbox" :title="child.model.number">
                            <input type="checkbox" v-model="child.selected" class="mr-1" @change="childSelectionChanged($event, child)">{{child.model.name}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>