<script setup>    
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { SecurityRole, UserType, UserState } from '@/constants.js';
    import { useToast } from 'vue-toastification';    
    import axios from 'axios';
    import { useUserStore } from '@/stores/user'
    import CustomerAccountTree from '@/views/Customers/CustomerAccountTree.vue';

    const props = defineProps(["user", "demoMode"]);
    const rows = ref([]);
    const customers = ref([])
    const pageInfo = ref({ sortKey: 'LastName' });
    const confirmModal = ref(null);
    const router = useRouter();
    const store = useUserStore();
    const accountTree = ref(null);

    async function search(parms) {        
        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);  
        const response = await axios.post("/api/Users/PagedList", pageInfo.value);            
        if (response.status !== 200) return;
        rows.value = response.data.rows;
        pageInfo.value = response.data.pageInfo;
    }

    async function impersonate(row) {        
        await confirmModal.value.open('Impersonate User?', `Are you sure you want to impersonate<br />${row.fullName}?`);
        await store.impersonate(row.id);
        router.push({ name: 'Home' });
    }

    async function loadFilters() {
        await Promise.all([
            axios.get('/api/Customer/List').then(x => customers.value = x.data)
        ]);
    }

    function onAccountFiltered(data) {        
        pageInfo.value.customerFilters = data;
        search();
    }

    async function deleteRow(row) {
        await confirmModal.value.open("Delete User?", `Are you sure you want to delete the user named:</br>"${row.fullName}"</br></br>Warning: This action is not reversible!`);
            
        const response = await axios.post(`/api/Users/Delete/${row.id}`);
        
        if (response.status !== 200) {
            useToast().error("Unable to delete user. Perhaps the record is in use?");
            return;            
        }

        search();
        useToast().success("The user was deleted successfully.");
    }
       
    onMounted(() => {
        loadFilters();
        search();
    });
</script>

<template>
    <div>
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons md-24">group</i> Users</p>
            </div>
            <div class="column is-narrow">
                <router-link class="button is-link" title="Add" :to="{name: 'AddUser'}">
                    <i class="material-icons">add</i>
                </router-link>
            </div>
        </div>
        
        <div class="columns notification is-info is-light p-0">
            <div class="column is-narrow">
                <i class="material-icons md-24">tips_and_updates</i>
            </div>
            <div class="column">
                Please note that &quot;Employee&quot; accounts are usually accessed using single sign. However, if you are accessing this site with a non-supported browser
                (Zebra scanner) then you may need to use the form-based login due to the browser compatibility issues. To initialize the password associated with an employee account
                use the <router-link :to="{name: 'ForgotPassword'}">forgot password</router-link> page.
            </div>
        </div>
                
        <div class="columns">
            <div class="column">
                <div title="Search" class="control is-expanded has-icons-right">
                    <input class="input" type="text" placeholder="Search" v-on:keyup.enter="search({pageNumber:1})" v-model="pageInfo.search">
                    <span class="icon is-right"><i class="material-icons">search</i></span>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-narrow">
                <div class="heading-md">User Type</div>
                <div class="box">
                    <div>
                        <label class="checkbox">
                            <input type="checkbox" v-model="pageInfo.userTypes" :true-value="[]" :value="UserType.Customer" @change="search({pageNumber:1})" /> Customer
                        </label>
                    </div>
                    <div>
                        <label class="checkbox">
                            <input type="checkbox" v-model="pageInfo.userTypes" :true-value="[]" :value="UserType.Employee" @change="search({pageNumber:1})" /> Employee
                        </label>
                    </div>
                </div>

                <div class="heading-md">Customer <span title="Filter the list by users who have access to the customer via 1 or more customer accounts." class="ml-1 material-icons md-18 has-text-info-dark cursor-help">help</span></div>
                <div class="box">
                    <CustomerAccountTree ref="accountTree" @changed="onAccountFiltered"></CustomerAccountTree>
                </div>
            </div>
            <div class="column">
                <div class="table-container">
                    <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                        <thead class="has-background-white-ter">
                            <tr>
                                <th></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="State" v-on:sorted="search">State</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="UserType" v-on:sorted="search">Type</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="LastName" v-on:sorted="search">Last Name</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="FirstName" v-on:sorted="search">First Name</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="Email" v-on:sorted="search">Email</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="MobilePhoneNumber" v-on:sorted="search">Mobile #</GridHeader></th>
                                <th v-if="demoMode == false"><GridHeader :pageInfo="pageInfo" sort-key="Facility.Name" v-on:sorted="search">Facility</GridHeader></th>
                                <th><GridHeader :pageInfo="pageInfo" sort-key="SecurityRole" v-on:sorted="search">Role</GridHeader></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in rows" v-bind:key="item.id">
                                <td class="is-narrow">
                                    <router-link class="has-text-black" :to="{ name : 'EditUser', params: { id: item.id}}" title="Edit"><i class="material-icons md-18">edit</i></router-link>
                                    <a v-if="user && user.id !== item.id && user.securityRole === SecurityRole.Admin" class="has-text-black" title="Delete" @click="deleteRow(item)"><i class="material-icons md-18">delete</i></a>

                                    <span class="ml-3">
                                        <span v-if="user && user.id !== item.id && user.securityRole === SecurityRole.Admin">
                                            <a class="has-text-black" title="Impersonate (Admins Only!)" @click="impersonate(item)"><i class="material-icons md-18">badge</i></a>
                                        </span>
                                        <span v-else title="This is your account!">
                                            <i class="material-icons md-18 has-text-warning-dark">star</i>
                                        </span>
                                    </span>

                                </td>
                                <td class="is-narrow">
                                    <span class="tags are-small">
                                        <span class="tag is-light" v-if="item.state === UserState.Unknown">?</span>
                                        <span class="tag is-warning" v-if="item.state === UserState.Pending">Pending</span>
                                        <span class="tag is-success" v-if="item.state === UserState.Active">Active</span>
                                        <span class="tag is-danger" v-if="item.state === UserState.Disabled">Disabled</span>
                                    </span>
                                </td>
                                <td class="is-narrow">
                                    <span class="tags are-small">
                                        <span class="tag is-primary" v-if="item.userType == UserType.Employee">Employee</span>
                                        <span class="tag is-black" v-if="item.userType == UserType.Customer">Customer</span>
                                    </span>
                                </td>
                                <td>{{item.lastName}}</td>
                                <td>{{item.firstName}}</td>
                                <td class="is-narrow"><a v-bind:href="`mailto:${item.email}`">{{item.email}}</a></td>
                                <td class="is-narrow"><a v-bind:href="`tel:${item.mobilePhoneNumber}`">{{item.mobilePhoneNumber}}</a></td>
                                <td v-if="demoMode == false">
                                    <span v-if="item.facility">
                                        <router-link :to="{ name : 'EditFacility', params: { id: item.facilityId}}" title="Edit">{{item.facility.name}}</router-link>
                                    </span>
                                    <span v-else>
                                        N/A
                                    </span>
                                </td>
                                <td class="is-narrow">
                                    <span v-if="item.securityRole === SecurityRole.User">User</span>
                                    <span v-if="item.securityRole === SecurityRole.Admin">Admin</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
            </div>
        </div>
    </div>
</template>

