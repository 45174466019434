<script setup>
    import { ref, computed, onMounted } from 'vue';
    import utils from '@/utils.js';
    import axios from 'axios';
    import constants from '@/constants.js';
    import { useToast } from 'vue-toastification';

    const defaultPaging = {
        sortKey: "ShipperFillDate",
        sortOrder: "desc",
        search: "",
        firstAddress: null,
        firstCarrier: null,
        FirstLegNumber: null,
        secondAddress: null,
        secondCarrier: null,
        SecondLegNumber: null,
        returnAddress: null,
        returnCarrier: null,
        ReturnLegNumber: null,
        customerName: null,
        customerNumber: null,
        shipperSerialNumber: null,
        sentryNumber: null,
        probeSentryNumber: null,
        jdeOrderNumber: null,
        shipperModelId: null,
        facilityId: null,
        pageNumber: 1
    };

    const rows = ref([]);
    const facilities = ref([]);
    const shipperModels = ref([]);
    const customers = ref([]);
    const states = ref([]);
    const carriers = ref([]);
    const loading = ref(false);
    const pageInfo = ref(defaultPaging);
    const showFilters = ref(false);
    const confirmModal = ref(null);
    const props = defineProps(["user", "demoMode"]);

    async function search(parms) {        
        if (parms) pageInfo.value = Object.assign(pageInfo.value, parms);
        loading.value = true;
        try {
            const response = await axios.post("/api/Shipment/List", pageInfo.value);
            if (response.status !== 200) return;
            rows.value = response.data.rows;
            pageInfo.value = response.data.pageInfo;
        }
        finally {
            loading.value = false;
        }
    }

    async function deleteRow(row) {
        await confirmModal.value.open("Delete Shipment?", `Are you sure you want to delete this shipment for order # ${row.jdeOrderNumber}?</br><br/>This will also clear the submitted status from the preparation checklist and delete the return checklist if it exists. <br/></br>Warning: This action is not reversible!`);
        const response = await axios.post(`/api/Shipment/Delete/${row.id}`);

        if (response.status !== 200) {
            useToast().error("Unable to delete record. Perhaps the record is in use?");
            return;
        }
        
        rows.value.splice(rows.value.indexOf(row), 1);
        useToast().success("The record was deleted successfully.");
    }

    function reset() {
        pageInfo.value = Object.assign({}, defaultPaging);
        search();
    }

    async function loadFilters() {
        await Promise.all([
            axios.get('/api/ShipperModel/List').then(x => shipperModels.value = x.data),
            axios.get('/api/Facility/List').then(x => facilities.value = x.data),
            axios.get('/api/Tracking/ListCarriers').then(x => carriers.value = x.data),
            axios.get('/api/Customer/List').then(x => customers.value = x.data),
            axios.get('/api/Shipment/ListStates').then(x => states.value = x.data)
        ]);
    }

    const formatTrackingLink = (item, leg) => {
        var trackingNumber = item[`${leg}LegTrackingNumber`];
        var carrier = item[`${leg}LegCarrier`];

        if (props.demoMode === true)
            trackingNumber = utils.censorText(trackingNumber, trackingNumber.length / 2);

        if( carrier == "fedex" && trackingNumber.length > 12 )
            trackingNumber = trackingNumber.slice(-12);

        const trackingLink = item[`${leg}LegTrackingLink`];
        if ( trackingLink )
            return `<a href="${trackingLink}" target="_blank">${trackingNumber}</a>`;
        else
            return `<span v-else>${trackingNumber}</span>`;
    }

    function formatMapLink(item, prefix) {        
        let parts = [];
        let lines = [];

        if (item[`${prefix}DeliveryAddressLine1`]) {
            lines.push(item[`${prefix}DeliveryAddressLine1`]);
            parts.push(item[`${prefix}DeliveryAddressLine1`]);
        }

        if (item[`${prefix}DeliveryAddressLine2`]) lines.push(item[`${prefix}DeliveryAddressLine2`]);
        if (item[`${prefix}DeliveryAddressLine3`]) lines.push(item[`${prefix}DeliveryAddressLine3`]);

        let lastLine = "";

        if (item[`${prefix}DeliveryCity`]) {
            lastLine += item[`${prefix}DeliveryCity`];
            parts.push(item[`${prefix}DeliveryCity`]);
        }

        if (item[`${prefix}DeliveryStateProvince`]) {
            lastLine += (lastLine.length > 0 ? " " : "") + item[`${prefix}DeliveryStateProvince`];
            parts.push(item[`${prefix}DeliveryStateProvince`]);
        }

        if (item[`${prefix}DeliveryPostalCode`]) {
            lastLine += (lastLine.length > 0 ? " " : "") + item[`${prefix}DeliveryPostalCode`];
            parts.push(item[`${prefix}DeliveryPostalCode`]);
        }

        if (item[`${prefix}DeliveryCountry`]) {
            lastLine += (lastLine.length > 0 ? ", " : "") + item[`${prefix}DeliveryCountry`];
            parts.push(item[`${prefix}DeliveryCountry`]);
        }

        lines.push(lastLine);

        if (props.demoMode == true && prefix !== 'return') {            
            return utils.censorText(lines.join(" "));
        }

        return `<a href='https://maps.google.com/?q=${(parts.join(" "))}' title='View using Google maps...' target='blank'><div class="address">${(lines.map(x => `<div>${x}</div>`).join(""))}</div></a>`;        
    }


    const isAdmin = computed(() => props.user && props.user.securityRole == constants.SecurityRole.Admin);
    const isEmployee = computed(() => props.demoMode == false && props.user && props.user.userType == constants.UserType.Employee);    

    onMounted(() => {
        loadFilters();
        reset();

        utils.doubleScroll(document.getElementsByClassName('table-container')[0]);
    })
</script>

<template>
    <div>
        <ConfirmModal ref="confirmModal"></ConfirmModal>

        <div class="columns">
            <div class="column">
                <p class="title"><i class="material-icons mr-1">dashboard</i>Dashboard</p>
            </div>
            <div class="column is-narrow">
                <button class="button" title="Reset List" v-on:click="reset">
                    <i class="material-icons mr-1">backspace</i> Reset Filters
                </button>
            </div>
        </div>

        <div class="notification is-warning is-light p-2 box">

            <strong>Disclaimer:</strong>  Every effort has been made by Linde to provide data accessible for viewing on this dashboard that is current and accurate.  Some of the data has been compiled from third party sources.  While all of the data is believed to be reliable, its accuracy and completeness cannot be guaranteed.  Linde provides all of the data on an &quot;as is&quot; basis.
        </div>

        <div v-show="loading">
            <div class="heading-md">Loading data, please wait...</div>
            <progress class="progress is-large is-info" max="100">60%</progress>
        </div>
        <div v-show="!loading">
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox" v-model="pageInfo.showArchived" @change="search()">
                    Show Archived Records? <span class="material-icons md-18 cursor-help" title="Records are considered archived when they are older than 60 days old.">help</span>
                </label>
            </div>

            <div class="table-container" id="table-container">
                <table class="table is-striped is-hoverable is-bordered is-narrow is-fullwidth">
                    <thead class="has-background-white-ter">
                        <tr class="has-background-info-light">
                            <th colspan="8">General</th>
                            <th colspan="5"><span class="material-icons md-18 mr-1">local_shipping</span>First Destination</th>
                            <th colspan="5"><span class="material-icons md-18 mr-1">local_shipping</span>Second Destination</th>
                            <th colspan="5"><span class="material-icons md-18 mr-1">local_shipping</span>Return Destination</th>
                            <th colspan="2">Device Information</th>
                            <th colspan="3" v-if="isEmployee"><span class="material-icons md-18 mr-1">lock</span>Linde Employee</th>
                        </tr>
                        <tr>
                            <th class="is-narrow"></th>
                            <th class="nowrap">
                                <GridHeader :pageInfo="pageInfo" sort-key="JdeOrderNumber" v-on:sorted="search">Order #</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.jdeOrderNumber"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({jdeOrderNumber : null, pageNumber: 1})"
                                           title="Search by Order Number">
                                </div>
                            </th>
                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="CustomerName" v-on:sorted="search">Customer</GridHeader>
                                <div class="select control is-fullwidth is-small" v-if="customers">
                                    <select v-model="pageInfo.customerId" @change="search({pageNumber: 1})">
                                        <option :value="null"></option>
                                        <option v-for="option in customers" v-bind:key="option.id" v-bind:value="option.id">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>
                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="ShipperModel" v-on:sorted="search">Shipper Model</GridHeader>
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.shipperModelId" @change="search({pageNumber: 1})">
                                        <option :value="null"></option>
                                        <option v-for="option in shipperModels" v-bind:key="option.id" v-bind:value="option.id">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>
                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="State" v-on:sorted="search">Status</GridHeader>                                
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.state" @change="search({pageNumber: 1})">
                                        <option v-for="option in states" v-bind:key="option.value" v-bind:value="option.value">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>

                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="TotalTripDuration" v-on:sorted="search">
                                    Total Trip Duration
                                </GridHeader>
                            </th>
                            <th class="nowrap">
                                <GridHeader :pageInfo="pageInfo" sort-key="ShipperFillDate" v-on:sorted="search">
                                    Dry Ice<br />Fill Date
                                </GridHeader>
                            </th>
                            <th>Dry Ice Hold Time <span class="material-icons md-18" title="The Dry Ice Hold time listed below is only applicable under &quot;Normal Conditions&quot; which assumes&#10;(i) environmental and handling conditions for the SECCURUS system are less severe than those defined in International Safe Transit Association 7D (temperature test for transport packaging) and 3A (simulation test for individual packaged products shipped through a parcel delivery system) protocols;&#10;(ii) the SECCURUS system is in a closed state, in good working condition, and not damaged over the course of the specified dry ice hold time period; and&#10;(iii) the total contents introduced into the SECCURUS system do not require greater heat removal than the number of 2 mL cryovials specified as the capacity for the SECCURUS system (20 for DI-1-90, 80 for DI-6-90, 200 for DI-9-152, and 500 for DI-15-216) filled with aqueous solution and starting in a frozen condition.">info</span></th>

                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="FirstLegDeliveryCity" v-on:sorted="search">Address</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.firstLegAddress"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({firstLegAddress : null, pageNumber: 1})"
                                           title="Search First Address">
                                </div>
                            </th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="FirstLegShipDate" v-on:sorted="search">Ship Date</GridHeader></th>
                            <th class="nowrap">
                                <GridHeader :pageInfo="pageInfo" sort-key="FirstLegCarrier" v-on:sorted="search">Ship via</GridHeader>
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.firstLegCarrier" @change="search({pageNumber: 1})">
                                        <option :value="null"></option>
                                        <option v-for="option in carriers" v-bind:key="option.slug" v-bind:value="option.slug">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>
                            <th class="nowrap">
                                <GridHeader :pageInfo="pageInfo" sort-key="FirstLegTrackingNumber" v-on:sorted="search">Tracking #</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.firstLegTrackingNumber"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({firstLegTrackingNumber: null, pageNumber: 1})"
                                           title="Search by Tracking Number">
                                </div>
                            </th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="FirstLegDeliveryDate" v-on:sorted="search">Est./Actual Delivery Date</GridHeader></th>

                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="SecondLegDeliveryCity" v-on:sorted="search">Address</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.secondLegAddress"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({secondLegAddress : null, pageNumber: 1})"
                                           title="Search Second Address">
                                </div>
                            </th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="SecondLegShipDate" v-on:sorted="search">Ship Date</GridHeader></th>
                            <th class="nowrap">
                                <GridHeader :pageInfo="pageInfo" sort-key="SecondLegCarrier" v-on:sorted="search">Ship via</GridHeader>
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.secondLegCarrier" @change="search({pageNumber: 1})">
                                        <option :value="null"></option>
                                        <option v-for="option in carriers" v-bind:key="option.slug" v-bind:value="option.slug">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>
                            <th class="nowrap">
                                <GridHeader :pageInfo="pageInfo" sort-key="SecondLegTrackingNumber" v-on:sorted="search">Tracking #</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.secondLegTrackingNumber"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({secondLegTrackingNumber :null, pageNumber: 1})"
                                           title="Search by Tracking Number">
                                </div>
                            </th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="SecondLegDeliveryDate" v-on:sorted="search">Est./Actual Delivery Date</GridHeader></th>

                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="ReturnLegDeliveryCity" v-on:sorted="search">Address</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.returnLegAddress"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({returnLegAddress : null, pageNumber: 1})"
                                           title="Search Return Address">
                                </div>
                            </th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="ReturnLegShipDate" v-on:sorted="search">Ship Date</GridHeader></th>
                            <th class="nowrap">
                                <GridHeader :pageInfo="pageInfo" sort-key="ReturnLegCarrier" v-on:sorted="search">Ship via</GridHeader>
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.returnLegCarrier" @change="search({pageNumber: 1})">
                                        <option :value="null"></option>
                                        <option v-for="option in carriers" v-bind:key="option.slug" v-bind:value="option.slug">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>
                            <th class="nowrap">
                                <GridHeader :pageInfo="pageInfo" sort-key="ReturnLegTrackingNumber" v-on:sorted="search">Tracking #</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.returnLegTrackingNumber"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({returnLegTrackingNumber : null, pageNumber: 1})"
                                           title="Search by Tracking Number">
                                </div>
                            </th>
                            <th><GridHeader :pageInfo="pageInfo" sort-key="ReturnLegDeliveryDate" v-on:sorted="search">Est./Actual Delivery Date</GridHeader></th>

                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="ProbeSentryNumber" v-on:sorted="search">Temperature Monitor Serial #</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.probeSentryNumber"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({probeSentryNumber : null, pageNumber: 1})"
                                           title="Search by Probe SENTRY Serial Number">
                                </div>
                            </th>
                            <th>
                                <GridHeader :pageInfo="pageInfo" sort-key="ShipperSerialNumber" v-on:sorted="search">Shipper Serial #</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.shipperSerialNumber"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({shipperSerialNumber : null, pageNumber: 1})"
                                           title="Search by Shipper Serial Number">
                                </div>
                            </th>                            
                            <th class="nowrap" v-if="isEmployee">
                                <GridHeader :pageInfo="pageInfo" sort-key="ReturnJdeOrderNumber" v-on:sorted="search">
                                    Return<br> Order #
                                </GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.returnJdeOrderNumber"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({returnJdeOrderNumber : null, pageNumber: 1})"
                                           title="Search by Return Order Number">
                                </div>
                            </th>
                            <th class="nowrap" v-if="isEmployee">
                                <GridHeader :pageInfo="pageInfo" sort-key="SentryNumber" v-on:sorted="search">Shipper<br />SENTRY #</GridHeader>
                                <div class="control">
                                    <input class="input is-small" type="text" v-model="pageInfo.shipperSentryNumber"
                                           v-on:keyup.enter="search({pageNumber: 1})"
                                           v-on:keyup.delete="search({shipperSentryNumber : null, pageNumber: 1})"
                                           title="Search by SENTRY Barcode Number">
                                </div>
                            </th>
                            <th v-if="isEmployee && facilities">
                                <GridHeader :pageInfo="pageInfo" sort-key="FacilityName" v-on:sorted="search">LG&amp;E Facility</GridHeader>
                                <div class="select control is-fullwidth is-small">
                                    <select v-model="pageInfo.facilityId" @change="search({pageNumber: 1})">
                                        <option :value="null"></option>
                                        <option v-for="option in facilities" v-bind:key="option.id" v-bind:value="option.id">
                                            {{option.name}}
                                        </option>
                                    </select>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in rows" v-bind:key="item.id">
                            <td class="nowrap is-narrow">                   
                                <div class="columns is-marginless is-gapless">
                                    <div class="column">
                                        <router-link class="has-text-black" :to="{ name : 'Shipment', params: { id: item.id}}">
                                            <i class="material-icons md-18">{{(isAdmin ? "edit" : "read_more")}}</i>
                                        </router-link>
                                        <a v-if="item.probeUri" class="has-text-black" title="Elpro LiveLink" :href="item.probeUri" target="_blank"><i class="material-icons md-18">thermostat</i></a>
                                    </div>
                                    <div class="column is-narrow" v-if="isAdmin">
                                        <a class="ml-2 has-text-black" title="Delete" v-on:click="deleteRow(item)"><i class="material-icons md-18">delete</i></a>
                                    </div>
                                </div>                            
                            </td>
                            <td class="is-narrow" align="right">
                                <router-link :to="{ name : 'Shipment', params: { id: item.id}}" title="View Shipment">
                                    {{item.jdeOrderNumber}}
                                </router-link>
                            </td>
                            <td class="nowrap">
                                <span v-if="demoMode == true">
                                    {{utils.censorText(item.customerName)}}
                                </span>
                                <span v-else>
                                    {{item.customerName}}
                                </span>
                            </td>
                            <td class="nowrap">{{item.shipperModel}}</td>
                            <td>
                                <span v-html="utils.formatShipmentState(item.state)" />
                            </td>
                            <td class="nowrap" align="right"><span v-if="item.totalTripDuration">{{utils.formatNumber(item.totalTripDuration)}} day(s)</span></td>
                            <td class="nowrap">{{utils.formatDate(item.shipperFillDate)}}</td>
                            <td class="nowrap" align="right">{{item.shipperDryIceHoldTime}} day(s)</td>

                            <td class="nowrap" v-html="formatMapLink(item, 'firstLeg')"></td>
                            <td class="nowrap">{{utils.formatDate(item.firstLegShipDate)}}</td>
                            <td><span v-html="utils.formatShippingCarrier(item.firstLegCarrier)"></span></td>
                            <td class="nowrap" v-html="formatTrackingLink(item, 'first')"></td>
                            <td class="nowrap">{{utils.formatDate(item.firstLegDeliveryDate)}}</td>

                            <td class="nowrap" v-html="formatMapLink(item, 'secondLeg')"></td>
                            <td class="nowrap">{{utils.formatDate(item.secondLegShipDate)}}</td>
                            <td><span v-html="utils.formatShippingCarrier(item.secondLegCarrier)"></span></td>
                            <td class="nowrap" v-html="formatTrackingLink(item, 'second')"></td>
                            <td class="nowrap">{{utils.formatDate(item.secondLegDeliveryDate)}}</td>

                            <td class="nowrap" v-html="formatMapLink(item, 'returnLeg')"></td>
                            <td class="nowrap">{{utils.formatDate(item.returnLegShipDate)}}</td>
                            <td><span v-html="utils.formatShippingCarrier(item.returnLegCarrier)"></span></td>
                            <td class="nowrap" v-html="formatTrackingLink(item, 'return')"></td>
                            <td class="nowrap">{{utils.formatDate(item.returnLegDeliveryDate)}}</td>

                            <td>{{item.probeSentryNumber}}</td>
                            <td>{{item.shipperSerialNumber}}</td>             
                            <td v-if="isEmployee">{{item.returnJdeOrderNumber}}</td>
                            <td v-if="isEmployee">{{item.shipperSentryNumber}}</td>
                            <td class="nowrap" v-if="isEmployee">{{item.facilityName}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <GridPager :pageInfo="pageInfo" v-on:paged="search"></GridPager>
        </div>
        <a class="is-warning" href="mailto:seccurus@linde.com">For questions regarding this dashboard, please contact us</a>
    </div>
</template>

